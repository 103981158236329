export const environment = {
  envName: 'PROD',
  production: true,
  firebase: {
    // apiKey: "AIzaSyCOlClpUoTJPSGTqxSY-AQqSYJZlJYCwQ8",
    // authDomain: "dilettapay-prod.firebaseapp.com",
    // databaseURL: "https://dilettapay-prod.firebaseio.com",
    // projectId: "dilettapay-prod",
    // storageBucket: "dilettapay-prod.appspot.com",
    // messagingSenderId: "230871216499",
    // appId: "1:230871216499:web:b541563d531ddcffa39506",
    // measurementId: "G-YKS90W6ER1"
    apiKey: "AIzaSyB5JN2b5LtkzaK8RgCi3AAj0GtAcr0NvRY",
    authDomain: "inovanti-prd.firebaseapp.com",
    databaseURL: "https://inovanti-prd.firebaseapp.com",
    projectId: "inovanti-prd",
    storageBucket: "inovanti-prd.appspot.com",
    messagingSenderId: "308918595319",
    appId: "1:308918595319:web:d9badaf9404e1f2b95edf2"
  },

  tenants: [
    {
      tenant: "Inovanti-oru6c",
      name: "inovanti",
      primaryColor: "#F2E500",
      overPrimary: "#000000",
      icon: "https://storage.googleapis.com/inovanti-prd.appspot.com/tenant-assets/InovantiBank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTY1MTgwODk3MTEwNywiYXVkIjoiSW5vdmFudGktb3J1NmMiLCJ0ZW5hbnRJZCI6Iklub3ZhbnRpLW9ydTZjIn0.1fVdFX1JLBtrPx7SsEkQHco2suk_YEOlqp6CBmhHfKKzfP-m9A_lY7mnn6Zj5xMYsol5vt0hPOZiYOICoEutQw",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false
    }
  ],
  apiUrl: 'https://inovanti-prod-vpc-dot-inovanti-prd.uc.r.appspot.com',
  keyEncryptAuth: 'oGTy7QtyHr48GE6re5KTckkGnbm6oo7SwRHsi2gqjapqflY9hjTAJ4GyUtt9YrSK',
  account: '413453',
  branch: '3100',
  wallet: '109',
  bank: '341',
  currency: '9'
};